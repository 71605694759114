<template>
  <div class="main">
    <div class="outer">
      <div class="item1">
        <div class="all-num">
          <div>{{ oldData.olderInfo.streetPopulationCount }}</div>
          <div>街道人口总数</div>
        </div>
        <div class="older-num">
          <div style="color: #ff8529">
            {{ oldData.olderInfo.elderPopulationCount }}
          </div>
          <div>老龄人口总数</div>
        </div>
      </div>
      <div class="item2">
        <div id="oldrate"></div>
        <div class="desc">老龄化 {{ oldData.olderInfo.elderRatio }}%</div>
      </div>
      <div class="item3">
        <div class="older-health">
          <div class="health-type">
            <div>
              <span>高龄老人</span>
              <span style="color: #32cf57"
                >{{ oldData.olderInfo.seniorPopulationCount }}
              </span>
            </div>
            <div>
              <span>残疾老人</span>
              <span>{{ oldData.olderInfo.disablePopulationCount }}</span>
            </div>
            <div>
              <span>留守老人</span>
              <span style="color: #ff3c1d">{{
                oldData.olderInfo.singlePopulationCount
              }}</span>
            </div>
            <div>
              <span>特困老人</span>
              <span style="color: #b620e0">{{
                oldData.olderInfo.poorPopulationCount
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import dachuanData from '@/assets/dachuanData/index.js'

export default {
  data() {
    return {
      oldData: {
        olderInfo: {
          streetPopulationCount: 0, // 街道人口总数
          elderPopulationCount: 0, //  老龄人口总数
          elderRatio: 0, // 老龄化比例
          seniorPopulationCount: 0, // 高龄老人数量
          seniorRatio: 0, // 高龄老人比例
          singlePopulationCount: 0, // 孤寡老人数量
          singleRatio: 0, //  孤寡老人比例
          disablePopulationCount: 0, // 残障老人数量
          disableRatio: 0, // 残障老人比例
          poorPopulationCount: 0, // 贫困老人数量
          poorRatio: 0, // 贫困老人比例
        },
      },
      oldDataDs: dachuanData.ds_data.oldData,
      oldDataGc: dachuanData.gc_data.oldData,
    }
  },
  computed: {
    type() {
      return this.$route.query.type
    },
  },
  mounted() {
    this.statisticalDataCollection()
  },
  methods: {
    statisticalDataCollection() {
      if (this.type == 'ds') {
        this.oldData = this.oldDataDs
      } else {
        this.oldData = this.oldDataGc
      }
      this.initBaseInfo()
    },
    initBaseInfo() {
      var getvalue = this.oldData.olderInfo.elderRatio
      var oldRate = echarts.init(document.getElementById('oldrate'))
      let option = {
        color: ['#FF6F0E', '#1F2A64'],
        tooltip: {
          show: false,
        },
        series: [
          {
            hoverAnimation: false,
            type: 'pie',
            radius: ['65%', '100%'],
            label: {
              show: true,
              position: 'center',
              formatter: '老龄化',
              color: '#69B1FF',
              fontWeight: 'bold',
            },
            data: [
              { value: getvalue, name: '老龄化' },
              { value: 100 - getvalue, name: '' },
            ],
          },
        ],
      }
      oldRate.setOption(option)
      window.addEventListener('resize', function () {
        oldRate.resize()
      })
    },
  },
}
</script>

<style scoped>
.older-health {
  height: 100%;
  width: 100%;
}

.box-contain {
  display: grid;
  grid-template-columns: repeat(2, 5.42rem);
  grid-template-rows: repeat(2, 3.33rem);
  gap: 0.3rem;
}

.age-item {
  width: 100%;
  height: 3.33rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
}
.age-item :first-child {
  width: fit-content;
  margin: 0 auto;
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #b4d3fe;
  line-height: 1.56rem;
  margin-top: 0.46rem;
  margin-bottom: 0.33rem;
}
.age-item :last-child {
  width: fit-content;
  margin: 0 auto;
  font-size: 0.58rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.67rem;
}

.health-type {
  width: 11.5rem;
  height: 7.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.health-type div {
  padding: 0 0.5rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
  height: 1.5rem;
  width: calc(100% - 1rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.health-type div :first-child {
  font-size: 0.58rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #69b1ff;
  line-height: 0.83rem;
}
.health-type div :last-child {
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff8529;
  line-height: 1.45rem;
}

.main {
  background: url('~@/assets/images/institution_images/baseInfoBg.png');
  width: 586px;
  height: 232px;
  overflow: hidden;
}
.outer {
  margin-top: 44px;
  height: 186px;
  width: 100%;
  padding: 0 10px 10px;
  display: grid;
  grid-template-columns: 128px 140px 278px;
  gap: 10px;
}
.item1,
.item2,
.item3 {
  width: 128px;
  height: 176px;
}
.item2 {
  width: 140px;
}
.item3 {
  width: 278px;
}
.item1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item1 .all-num {
  width: 128px;
  height: 83px;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item1 .all-num :first-child,
.item1 .older-num :first-child {
  margin-top: 9px;
  font-size: 30px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #b4d3fe;
  line-height: 35px;
}
.item1 .all-num :last-child,
.item1 .older-num:last-child {
  margin-top: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 16px;
}
.item1 .older-num {
  width: 128px;
  height: 83px;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item2 {
  height: 174px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #213060;
}
.item2 #oldrate {
  width: 100px;
  height: 100px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.item3 #oldtype {
  width: 100%;
  height: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
}
.item2 .desc {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 16px;
}
</style>
